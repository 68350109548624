import { Formik } from 'formik';
import { isMatch } from 'lodash';
import React from 'react';
import { Book, Briefcase, Lock, Mail, MapPin, Phone, User } from 'react-feather';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { EntryFormContainer } from '../../../components';
import { Breadcrumb, Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs';
import { FormBackButton, FormButton, FormStepper, FormTextInput } from '../../../components/form';
import Layout, { StickyBackground } from '../../../components/layout';
import { CreateIndividualModel } from '../../../models/request';
import { UsersThunk } from '../../../store/users/users.thunk';
import { GendersMap, MeansOfIdList } from '../../../util/constants';
import { validateFormValues } from '../../../util/validation';
import { RegistrationStages } from './common';

const MeansOfIdField = () => (
    <div className="col-6">
        <FormTextInput
            name="MeansOfId"
            placeholder="MEANS OF ID"
            type="text"
            as="select"
            icon={Briefcase}
            className="wide"
        >
            <option value={0} disabled>
                MEANS OF ID
            </option>
            {MeansOfIdList.map((value, i) => (
                <option value={value} key={i}>
                    {value}
                </option>
            ))}
        </FormTextInput>
    </div>
);

// const ClientTypeField = () => (
//     <div className="col-6">
//         <FormTextInput
//             name="ClientTypeId"
//             placeholder="CLIENT TYPE"
//             type="text"
//             as="select"
//             icon={Briefcase}
//             className="wide"
//         >
//             <option value={0} disabled>
//                 CLIENT TYPE
//             </option>
//             {Object.entries(ClientTypesMap).map(([value, name], i) => (
//                 <option value={value}>{name}</option>
//             ))}
//         </FormTextInput>
//     </div>
// );

export const RegisterIndividualPage: React.FC = () => {
    const breadcrumbs: Breadcrumb[] = [
        { name: 'Home', path: '/' },
        { name: 'Create account', path: window.location.href },
    ];

    const [currentStage, setCurrentStage] = React.useState<RegistrationStages>(RegistrationStages.FirstStage);
    const dispatch = useDispatch();

    const handleRegister = React.useCallback(
        async (values: CreateIndividualModel) => {
            values.ClientTypeId = 1;
            const formData = new FormData();

            Object.entries(values).forEach(([key, value]) => {
                formData.append(key, value);
            });

            dispatch(UsersThunk.createIndividual(formData));
        },
        [dispatch]
    );

    return (
        <Layout>
            <StickyBackground />
            <div className="px-5 py-3">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
                <EntryFormContainer>
                    <Formik
                        validate={validateFormValues}
                        initialValues={new CreateIndividualModel()}
                        onSubmit={handleRegister}
                    >
                        {({ handleSubmit, isValid, submitCount, initialValues, values }) => (
                            <form onSubmit={handleSubmit} className="d-flex flex-column align-items-center">
                                <header className="form__header mb-5">
                                    <span className="form__subheader">Individual</span>
                                    <FormBackButton currentStep={currentStage} setCurrentStep={setCurrentStage} />
                                    <h1 className="mb-3">Create an account</h1>
                                    <FormStepper steps={2} currentStep={currentStage} />
                                    {!isValid && submitCount > 0 && (
                                        <h6 className="error mt-2">Please check the form fields for errors</h6>
                                    )}
                                </header>
                                <main className="mb-4">
                                    <React.Fragment>
                                        <div className="form-row mb-2">
                                            <div className="col-6">
                                                <FormTextInput
                                                    className="wide"
                                                    name="FirstName"
                                                    placeholder="FIRST NAME"
                                                    type="text"
                                                />
                                            </div>
                                            <div className="col-6">
                                                <FormTextInput
                                                    className="wide"
                                                    name="LastName"
                                                    placeholder="LAST NAME"
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <div className="form-row mb-2">
                                            <div className="col-6">
                                                <FormTextInput
                                                    className="wide"
                                                    name="Registered_Address"
                                                    placeholder="ADDRESS"
                                                    type="text"
                                                    icon={MapPin}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <FormTextInput
                                                    className="wide"
                                                    name="Email"
                                                    placeholder="EMAIL ADDRESS"
                                                    type="email"
                                                    icon={Mail}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-row mb-2">
                                            <div className="col-6">
                                                <FormTextInput
                                                    className="wide"
                                                    name="PhoneNumber"
                                                    placeholder="MOBILE NUMBER"
                                                    type="text"
                                                    icon={Phone}
                                                />
                                            </div>
                                            <MeansOfIdField />
                                        </div>
                                        <div className="form-row mb-2">
                                            <div className="col-6">
                                                <FormTextInput
                                                    className="wide"
                                                    name="Identification"
                                                    placeholder="IDENTIFICATION NUMBER"
                                                    type="text"
                                                    icon={Book}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <FormTextInput
                                                    name="Gender"
                                                    placeholder="GENDER"
                                                    type="text"
                                                    as="select"
                                                    icon={User}
                                                    className="wide"
                                                >
                                                    <option value={''} disabled>
                                                        GENDER
                                                    </option>
                                                    {Object.entries(GendersMap).map(([value, name], i) => (
                                                        <option value={value} key={i}>
                                                            {name}
                                                        </option>
                                                    ))}
                                                </FormTextInput>
                                            </div>
                                        </div>
                                        <div className="form-row mb-2">
                                            <div className="col-6">
                                                <FormTextInput
                                                    className="wide"
                                                    name="Password"
                                                    placeholder="PASSWORD"
                                                    type="password"
                                                    icon={Lock}
                                                    toggleField
                                                />
                                            </div>
                                            <div className="col-6">
                                                <FormTextInput
                                                    className="wide"
                                                    name="Confirm_Password"
                                                    placeholder="CONFIRM PASSWORD"
                                                    type="password"
                                                    icon={Lock}
                                                    toggleField
                                                />
                                            </div>
                                        </div>
                                    </React.Fragment>
                                </main>
                                <footer className="d-flex flex-column align-items-center">
                                    <FormButton
                                        className="mb-4"
                                        type="submit"
                                        disabled={!isValid || isMatch(initialValues, values)}
                                    >
                                        <span>Complete registration</span>
                                    </FormButton>
                                    <span>
                                        Already have an account?{' '}
                                        <Link to="/login">
                                            <u>Log in</u>
                                        </Link>
                                    </span>
                                </footer>
                            </form>
                        )}
                    </Formik>
                </EntryFormContainer>
            </div>
        </Layout>
    );
};
